.landing-header.featured-section {
	background: linear-gradient(47deg, #dbcdff, #96d7ff);
}

.landing-header.featured-section .mt-5.mb-4.container {
	max-width: 1120px !important;
}
.dynamic-cards {
	width: 100%;
	padding: 1.2em;
	color: #fff;
	background: #fff;
	border-radius: 8px;
	min-height: 22rem;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: cover;
	background-origin: border-box;
}
.dynamic-cards.mb-3:hover {
	box-shadow: grey 0px 10px 16px 2px !important;
	transform: translateY(-5px) translateZ(0px) !important;
}

@media screen and (max-width: 800px) {
	.dynamic-cards {
		/* width: 100%; */
		padding: 1.2em;
		color: #fff;
		background: #fff;
		border-radius: 8px;
		min-height: 22rem;
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: cover;
		background-origin: border-box;
	}
}
@media screen and (max-width: 1000px) {
	.card-row {
		padding: 0 20vw;
	}
}

@media screen and (max-width: 768px) {
	.card-row {
		padding: 0 30vw;
	}
}
@media screen and (max-width: 750px) {
	.card-row {
		padding: 0 30vw;
	}
}
@media screen and (max-width: 550px) {
	.card-row > a {
		width: 400px;
	}

	.card-row {
		padding: 0 20vw;
	}
	.dynamic-cards {
		max-height: 200px;
	}
	.featured-section {
		width: 100vw;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
}
