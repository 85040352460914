.react-switch-checkbox {
	height: 0;
	width: 0;
	visibility: hidden;
}

.react-switch-label {
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	width: 30px;
	height: 20px;
	/* remain color */
	background: grey;
	border-radius: 100px;
	position: relative;
	transition: background-color 0.2s;
}

.react-switch-label .react-switch-button {
	content: '';
	position: absolute;
	top: 0px;
	left: 0px;
	width: 20px;
	height: 20px;
	border-radius: 20px;
	transition: 0.2s;
	/* remain color */
	background: #fff;
	box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
	left: calc(100% - 2px);
	transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
	width: 20px;
}
