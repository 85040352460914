.footer-container {
	padding: 4rem 0 2rem 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.footer-links {
	width: 100%;
	max-width: 1120px;
	display: flex;
	justify-content: space-around;
}

.footer-link-wrapper {
	display: flex;
}

.footer-link-items {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: 16px;
	text-align: left;
	width: 160px;
	box-sizing: border-box;
}

.footer-link-items p {
	margin-bottom: 16px;
}

.footer-link-items a {
	text-decoration: none;
	margin-bottom: 0.5rem;
	display: flex;
	align-items: center;
	/* remain this color */
	color: #009dff;
	font-weight: 500;
}
.footer-link-items a svg {
	margin-right: 4px;
	width: 20px;
	height: 20px;
}

.footer-link-items a:hover {
	color: var(--secondary);
	transition: 0.3s ease-out;
}

.footer-email-form h2 {
	margin-bottom: 2rem;
}

/* Social Icons */
.social-icon-link {
	font-size: 24px;
}

.social-media {
	max-width: 1000px;
	width: 100%;
}

.social-media-wrap {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 90%;
	max-width: 1000px;
	margin: 40px auto 0 auto;
}

.social-icon-link {
	margin-left: 15px;
	margin-right: 15px;
}

@media screen and (max-width: 1000px) {
	.footer-links {
		padding-top: 2rem;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.footer-input {
		width: 100%;
	}

	.btn {
		width: 100%;
	}

	.footer-link-wrapper {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
	}
	.footer-link-items {
		align-items: center;
	}

	.social-media-wrap {
		flex-direction: column;
	}
}

@media screen and (max-width: 500px) {
	.footer-link-wrapper {
		flex-direction: column;
	}
}
