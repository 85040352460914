.navbar {
	background: var(--primary);
	height: 45px;
	display: flex;
	justify-content: center !important;
	align-items: center !important;
	font-size: 1.2rem;
	position: sticky;
	top: 0;
	z-index: 999;

	padding: 0px !important;
}

.navbar-container {
	display: flex !important;
	justify-content: space-between !important;
	align-items: center !important;
	height: 45px;
	max-width: 1120px;
	width: 100%;
}

.navbar-logo {
	justify-self: start;
	min-width: 105px;
	height: 45px;
	cursor: pointer;
	text-decoration: none;
	padding: 0.5rem 0px;
}

.nav-menu {
	display: grid;
	grid-template-columns: repeat(4, auto);
	grid-gap: 10px;
	height: 45px;
	list-style: none;
	text-align: center;
	width: 60vw;
	justify-content: end;
	/* margin-right: 2rem; */
}

.nav-item {
	height: 45px;
	margin: 0px !important;
	display: flex !important;
	justify-content: space-between !important;
	align-items: center !important;
}

.nav-links {
	align-items: center;
	text-decoration: none;
	padding: 0.5rem 1rem;
	height: 100%;
}

.nav-links:hover {
	border-bottom: 4px solid var(--secondary);
	transition: all 0.2s ease-out;
}

.menu-icon {
	display: none;
}
.nav-links-mobile {
	display: none;
}
.switch-component {
	display: flex;
	align-items: center;
	font-size: smaller;
	min-width: 80px;
	padding: 0.5rem 1rem;
}

@media screen and (max-width: 960px) {
	.NavbarItems {
		position: relative;
	}

	.nav-menu {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100vh;
		position: absolute;
		top: 40px;
		left: -100%;
		opacity: 1;
		transition: all 0.5s ease;
		justify-content: space-evenly;
		padding: 100px 0px;
	}
	.navbar-container {
		max-width: 1120px;
	}
	.nav-menu.active {
		background: var(--primary);
		left: 0;
		opacity: 1;
		transition: all 0.5s ease;
		z-index: 1;
		border-top: 1px solid var(--secondary);
	}
	.nav-menu li {
		max-height: 200px;
	}
	.nav-links {
		text-align: center;
		padding: 2rem 0rem;
		width: 100%;
		display: table;
	}
	.toggleIcon {
		display: block;
	}

	.navbar-logo {
		position: absolute;
		left: 0;
		/* transform: translate(60%, 0%); */
		padding: 0px;
		margin-left: 25px;
		display: flex;
		align-items: center;
	}

	.menu-icon {
		display: block;
		position: absolute;
		right: 0;
		font-size: 1.2rem;
		cursor: pointer;
		width: 25px;
		height: 25px;
		margin-right: 25px;
		padding: 0px 15px;
	}
	.switch-component {
		position: absolute;
		right: 45%;
		width: 60px;
	}

	.nav-links-mobile {
		display: block;
		text-align: center;
		margin: 2rem auto;
		border-radius: 4px;
		width: 80%;
		text-decoration: none;
		font-size: 1.5rem;
		background-color: transparent;
		padding: 14px 20px;
		border: 1px solid var(--secondary);
		transition: all 0.3s ease-out;
	}

	.nav-links-mobile:hover {
		transition: 250ms;
	}
	ul {
		padding: 0px;
	}
	input {
		text-align: center;
	}
	.nav-links:hover {
		background-color: var(--primary);
		border: none !important;
		transform: translateY(-3px);
		font-size: 2rem;
	}
}

@media screen and (max-width: 425px) {
	.navbar-logo {
		font-size: 0.8rem;
	}
	.switch-component {
		position: absolute;
		right: 40%;
		width: 60px;
	}
}
