:root {
	--primary: #fff;
	--secondary: #333;
}

@font-face {
	font-family: CustomGoogleFont;
	src: url('assets/OpenSans-VariableFont_wdth,wght.ttf') format('truetype');
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: CustomGoogleFont;
}

ol,
ul {
	padding: 0 !important;
	margin: 0 !important;
}

.anchor {
	position: absolute;
	transform: translateY(-50vh);
}

.my-container {
	padding-left: 25px !important;
	padding-right: 25px !important;
}

.h1-title {
	margin-top: 0;
	margin-bottom: 1.5rem;
}

a:hover {
	/* color: #53a8fd !important; */
	color: var(--secondary) !important;
}

body {
	min-width: 375px;
}

h1 {
	font-size: 2rem;
}

h1 {
	letter-spacing: 4px;
	color: #53a8fd;
}

body.bg-dark h1 {
	color: #cce5fe;
}

body.bg-dark .featured-section h1 {
	color: #1a2337;
}

body.bg-dark a {
	color: var(--primary) !important;
}

body.bg-dark a:hover {
	color: #53a8fd !important;
	transition: 0.3s ease-out;
}

body.bg-dark {
	background-color: var(--secondary);
	color: var(--primary);
}

body.bg-dark nav {
	background-color: var(--secondary);
}

body.bg-dark .nav-menu.active {
	background: var(--secondary);
}

body.bg-dark .nav-links:hover {
	border-bottom: 4px solid var(--primary);
}

body.bg-dark .about-wrap .about-icon {
	color: var(--primary);
}

.home {
	display: flex;
	height: 90vh;
	align-items: center;
	justify-content: center;
	font-size: 3rem;
}

.products {
	/* background-image: url('./images/img-1.jpg'); */
	background-position: center;
	background-size: fill;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 100px;
}
