.divider-card-01 {
	height: 5rem;
}
.card_bg {
}
.cards h1 {
}

.cards__container {
	/* background-color: green; */
	margin: 0 auto;
	max-width: 1120px;
}

.cards__wrapper {
	position: relative;
	/* margin: 50px 0 45px; */
	display: flex;
	flex-flow: column;
	align-items: center;
}

.cards__items {
	margin-bottom: 24px;
	width: 100%;
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 2rem;
}

.cards__item {
	display: flex;
	flex: 1;
	border-radius: 10px;
	/* padding: 15px; */
	margin-bottom: 25px;
	max-width: 500px;
}
.cards__item:nth-child(1) {
	background: linear-gradient(180deg, #1cb5e0 0%, #000851 100%);
}
.cards__item:nth-child(2) {
	background-image: linear-gradient(135deg, #ffcf71 10%, #2376dd 100%);
}
.cards__item:nth-child(3) {
	background-color: #4158d0;
	background-image: linear-gradient(
		0deg,
		#4158d0 0%,
		#c850c0 46%,
		#ffcc70 100%
	);
}

.cards__item__link {
	display: flex;
	flex-flow: row;
	width: 100%;
	-webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
	filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
	border-radius: 10px;
	text-decoration: none;
	color: white !important;
	padding: 20px 30px;
}
.cards__item:hover {
	box-shadow: grey 0px 10px 16px 0px !important;
	transform: translateY(-2px) translateZ(0px) !important;
}

.cards__item__pic-wrap {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
}
figure {
	/* disable bootstrap figure margin */
	margin-bottom: 0 !important;
}

.fade-img {
	animation-name: fade-img;
	animation-duration: 2s;
}

.cards__item__img_wrap {
	position: relative;
	border-radius: 10px;
	overflow: hidden;
	margin-bottom: 0;
}
.cards__item__img_wrap::after {
	content: attr(data-category);
	position: absolute;
	bottom: 0;
	padding: 6px 8px;
	max-width: calc((100%) - 60px);
	font-size: 12px;
	font-weight: 700;
	color: #fff !important;
	background-color: #1f98f4;
	box-sizing: border-box;
}

.cards__item__img {
	display: block;
	width: 10rem;
	height: 10rem;
	transition: all 0.3s linear;
	object-fit: cover;
}

.cards__item__img:hover {
	transform: scale(1.2);
	transition: transform 0.5s;
}

.cards__item__info {
	padding: 20px 30px 30px;
}

.cards__item__text {
	font-size: 18px;
	line-height: 24px;
	margin-block-end: 0px;
}
#no_color_change:hover {
	color: white !important;
}

.card-extra-space {
	height: 1rem;
}
@media only screen and (min-width: 1200px) {
	.content__blog__container {
		/* width: 84%; */
	}
}

@media only screen and (min-width: 1024px) {
	.cards__items {
		display: flex;
	}
}

@media only screen and (max-width: 1200px) {
	.cards__item {
		margin-bottom: 2rem;
	}
}

@media only screen and (min-width: 1220px) {
}
@media screen and (max-width: 550px) {
	.cards__item__link {
		flex-direction: column;
		/* justify-content: flex-start;
		 */
		align-items: center;
	}
}
