.about-wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.about-wrap .about-area {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	position: relative;
}

.about-wrap .divider-about-1 {
	height: 200px;
	width: 100vw;
}

.about-wrap .divider-about-2 {
	height: 3rem;
}

.about-wrap .about-left-wrap {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	left: 0;
	top: 0;
	/* opacity: 0; */
	opacity: 1;
	/* transition: opacity 0.15s linear; */
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 2.5s;
	transform: translateX(-20vw);
	transition: 1.5s;
}

.about-wrap .about-heading {
	width: 100%;
	height: 8vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-image: linear-gradient(45deg,
			rgb(35 84 216 / 80%),
			rgb(238 174 255 / 70%));
	font-size: 1.5rem;
	border-radius: 5px;
	cursor: pointer;
}

.about-wrap .my-image-wrap {
	cursor: pointer;
	box-shadow: 0px 0px 8px gray;
	position: relative;
}

.about-wrap .my-image-wrap:hover {
	box-shadow: 0px 0px 15px gray;
}

.about-wrap .my-image {
	width: 300px;
}

.about-wrap .about-heading:hover {
	box-shadow: 0px 0px 8px gray;
}

.about-wrap .about-heading h1 {
	color: white;
}

.about-wrap .about-area-wrap {
	max-width: 1120px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.about-wrap .about {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 30px 20px;
	max-width: 600px;
}

.about-wrap .about-icon {
	width: 40px;
	height: 40px;
	padding: 8px;
	cursor: pointer;
	border-radius: 88px;
	/* color: white; */
	color: var(--secondary);
	animation: icon-general 3s infinite linear;
}

.about-wrap .about-icon-faShoePrints {
	rotate: 270deg;
}

.about-wrap .about-icon-faLaugh {
	position: absolute;
	color: #cfe2fe !important;
	/* move icon */
	animation: faLaugh-rotation 2s infinite linear;
}

@keyframes icon-general {
	from {
		box-shadow: 0px 0px 8px gray;
	}

	to {
		box-shadow: 0px 0px 0px gray;
	}
}

@keyframes faLaugh-rotation {
	from {
		transform: rotate(0deg);
		scale: initial;
	}

	50% {
		scale: 1.5;
	}

	to {
		transform: rotate(360deg);
		scale: initial;
	}
}

.about-wrap .about .about-title {
	padding: 20px 10px;
	cursor: pointer;
}

.about-wrap .about .about-content {
	padding: 40px 10px;
	font-size: 1.2rem;
}

.about.challenge-taking a,
.about.skill-stacking a,
.about.skill-box a {
	display: block;
}

.about-wrap .skill-box {
	margin-bottom: 10rem;
}

.skillbox-wrap {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

@media screen and (max-width: 1300px) {
	.skillbox-wrap {
		flex-direction: column;
	}

	.about-wrap .about-left-wrap {
		transform: translateX(-20vw);
		transition: 1.5s;
	}
}

@media screen and (max-width: 900px) {
	.about-wrap .about-area {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.about-wrap .about-left-wrap {
		justify-content: center;
		align-items: center;
		margin: 5rem auto;
		position: relative;
		left: 0;
		top: 0;
		transform: none;
	}

	.about-wrap .divider-about-1 {
		height: 0;
	}

	.about-wrap .divider-about-2 {
		height: 2rem;
	}
}