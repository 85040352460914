.brief-container {
	margin: 4rem auto;
	max-width: 1120px;
	width: 100%;
	letter-spacing: 1.5px;
}

.brief-container p {
	margin-top: 0;
	font-weight: 500;
	margin-bottom: 1rem;
	font-size: 1.3rem;
	line-height: 2.2rem;
	width: 65%;
	text-align: left;
}

.w3-animate-bottom {
	animation-delay: 750ms;
	animation-duration: 0.75s;
}
@media only screen and (max-width: 1200px) {
}
@media only screen and (max-width: 800px) {
	.brief-container p {
		width: 100%;
	}
}
